// ** React Import
import { useEffect } from 'react'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Import
import { useTranslation } from 'react-i18next'

// ** Custom Components Imports
import OptionsMenu from 'src/@core/components/option-menu'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import { Typography } from '@mui/material'

interface Props {
  settings: Settings
  saveSettings: (values: Settings) => void
}

const LanguageDropdown = ({ settings, saveSettings }: Props) => {
  // ** Hook
  const { i18n } = useTranslation()

  // ** Vars
  const { layout } = settings

  const handleLangItemClick = (lang: 'en' | 'zh' | 'ar') => {
    i18n.changeLanguage(lang)
  }

  // ** Change html `lang` attribute when changing locale
  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language)
  }, [i18n.language])

  useEffect(() => {
    const loaclLanguage = localStorage.getItem('language') as any

    if (loaclLanguage) {
      handleLangItemClick(loaclLanguage)

      saveSettings({ ...settings })
    }
  }, [])

  return (
    <OptionsMenu
      icon={<Typography>{i18n.language === 'en' ? 'EN' : '简体'}</Typography>}
      menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 130 } } }}
      iconButtonProps={{
        color: 'inherit',
        sx: { ...(layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }) }
      }}
      options={[
        {
          text: 'English',
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === 'en',
            onClick: () => {
              localStorage.setItem('language', 'en')

              handleLangItemClick('en')

              saveSettings({ ...settings })
            }
          }
        },
        {
          text: '中文',
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === 'zh',
            onClick: () => {
              localStorage.setItem('language', 'zh')

              handleLangItemClick('zh')

              saveSettings({ ...settings })
            }
          }
        }
      ]}
    />
  )
}

export default LanguageDropdown

// ** React Import
import { ReactNode } from 'react'

// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'
import LockOpenVariantOutline from 'mdi-material-ui/LockOpenVariantOutline'

// import LockOutLine from 'mdi-material-ui/LockOutLine'
import IconButton from '@mui/material/IconButton'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import { LayoutProps } from 'src/@core/layouts/types'

// ** Configs
import themeConfig from 'src/configs/themeConfig'
import { LockOutline } from 'mdi-material-ui'

interface Props {
  navHover: boolean
  hidden: boolean
  settings: LayoutProps['settings']
  toggleNavVisibility: () => void
  saveSettings: LayoutProps['saveSettings']
  verticalNavMenuBranding?: (props?: any) => ReactNode
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(4.5),
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight
}))

const HeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
  transition: 'opacity .25s ease-in-out, margin .25s ease-in-out'
}))

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const {
    verticalNavMenuBranding: userVerticalNavMenuBranding,
    hidden,
    navHover,
    settings,
    saveSettings,
    toggleNavVisibility
  } = props
  const { navCollapsed } = settings
  const menuCollapsedStyles =
    navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 }

  // ** Hooks
  const theme = useTheme()

  return (
    <MenuHeaderWrapper className="nav-header" sx={{ pl: 6 }}>
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : (
        <LinkStyled href="/" passHref>
          <img width={150} src="/images/logos/genomelight_logo.png" alt="" />

          {/* <HeaderTitle variant="h6" sx={{ ml: 3 }}>
            {themeConfig.templateName}
          </HeaderTitle> */}
        </LinkStyled>
      )}

      {hidden ? (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() =>
            saveSettings({ ...settings, navCollapsed: !navCollapsed })
          }
          sx={{ p: 0, backgroundColor: 'transparent !important' }}
        >
          <LockOpenVariantOutline />
        </IconButton>
      ) : (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() =>
            saveSettings({ ...settings, navCollapsed: !navCollapsed })
          }
          sx={{
            p: 0,
            color: 'text.primary',
            backgroundColor: 'transparent !important',
            '& svg': {
              fontSize: '1.25rem',
              ...menuCollapsedStyles,
              transition: 'opacity .25s ease-in-out'
            }
          }}
        >
          {/* <LockOutLine /> */}
          <LockOutline />
        </IconButton>
      )}
    </MenuHeaderWrapper>
  )
}

export default VerticalNavHeader

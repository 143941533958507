// ** Types
import { NextRouter } from 'next/router' // ** Types
import { NavGroup, NavLink } from 'src/@core/layouts/types'
import { NObject } from '../components/filter-variants'
import * as clipboard from 'clipboard-polyfill'

/**
 * Check for URL queries as well for matching
 * Current URL & Item Path
 *
 * @param item
 * @param activeItem
 */
export const handleURLQueries = (
  router: NextRouter,
  path: string | undefined
): boolean => {
  if (Object.keys(router.query).length && path) {
    const arr = Object.keys(router.query)

    return (
      router.asPath.includes(path) &&
      router.asPath.includes(router.query[arr[0]] as string) &&
      path !== '/'
    )
  }

  return false
}

/**
 * Check if the given item has the given url
 * in one of its children
 *
 * @param item
 * @param currentURL
 */
export const hasActiveChild = (item: NavGroup, currentURL: string): boolean => {
  const { children } = item

  if (!children) {
    return false
  }

  for (const child of children) {
    if ((child as NavGroup).children) {
      if (hasActiveChild(child, currentURL)) {
        return true
      }
    }
    const childPath = (child as NavLink).path

    // Check if the child has a link and is active
    if (
      child &&
      childPath &&
      currentURL &&
      (childPath === currentURL ||
        (currentURL.includes(childPath) && childPath !== '/'))
    ) {
      return true
    }
  }

  return false
}

function getRandom(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min)
}

/**
 * 获取随机6为字符串，包含数字大小写字母
 *
 * @returns string
 */
export const getCode = () => {
  let code = ''

  for (let i = 0; i < 6; i++) {
    const type = getRandom(1, 3)

    switch (type) {
      case 1:
        code += String.fromCharCode(getRandom(48, 57)) //数字
        break
      case 2:
        code += String.fromCharCode(getRandom(65, 90)) //大写字母
        break
      case 3:
        code += String.fromCharCode(getRandom(97, 122)) //小写字母
        break
    }
  }

  return code
}

export const judgeAcmg = (value: string) => {
  if (value) {
    const temp = value
      .split(' ')
      .join(',')
      .split('-')
      .join(',')
      .split(',')
      .map((item) => item.toLowerCase().substring(0, 1))
      .join('')

    switch (temp) {
      case 'us':
        return '意义未明的'
      case 'b':
        return '良性的'
      case 'lb':
        return '可能良性的'
      case 'p':
        return '致病的'
      case 'lp':
        return '可能致病的'

      default:
        return temp
    }
  } else {
    return ''
  }
}

/**
 * 将数组对象转为csv文件
 *
 * @param arr
 * @param columns
 * @param delimiter
 * @return string
 */
export const JSONtoCSV = (arr: any[], columns: any[], delimiter = ',') => {
  if (typeof columns[0] === 'string')
    return [
      columns.join(delimiter),
      ...arr.map((obj) =>
        columns.reduce(
          (acc, key) =>
            `${acc}${!acc.length ? '' : delimiter}"${
              JSON.stringify(obj[key]) || ''
            }"`,
          ''
        )
      )
    ].join('\n')
  else {
    return [
      columns.map((item) => item.title).join(delimiter),
      ...arr.map((obj) =>
        columns.reduce(
          (acc, key) =>
            `${acc}${!acc.length ? '' : delimiter}"${
              JSON.stringify(obj[key.key]) || '-'
            }"`,
          ''
        )
      )
    ].join('\n')
  }
}

/**
 * 获取版本号 形如：x.x.x-403cb51
 * @returns {string|*}
 */
export function getVersion() {
  const APP_VERSION = process.env.npm_package_version

  console.log(APP_VERSION)

  return APP_VERSION
}

/**
 * 解析URL的查询字符串
 * @param url string
 * @returns {object}
 */
export function parseUrlQuery(url: string) {
  // 创建一个URL对象
  const parsedUrl = new URL(url)

  // 获取查询字符串并创建URLSearchParams对象
  const searchParams = new URLSearchParams(parsedUrl.search)

  // 创建一个空对象来存储键值对
  const queryObj: NObject = {}

  // 遍历URLSearchParams对象，并将每个键值对添加到queryObj中
  for (const [key, value] of searchParams.entries()) {
    queryObj[key] = value
  }

  // 返回包含键值对的对象
  return queryObj
}

/**
 * 复制文本到剪贴板
 * @param textToCopy string
 * @returns {null}
 */
export function copyToClipboard(textToCopy: any, e: any) {
  // navigator clipboard 需要https等安全上下文
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy)
  } else {
    clipboard.writeText(textToCopy).then(
      () => {
        console.log('success!')
      },
      () => {
        console.log('error!')
      }
    )
  }
}

import * as React from 'react'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface State extends SnackbarOrigin {
  open: boolean
}
export type AlertColor = 'success' | 'info' | 'warning' | 'error'

const PositionedSnackbar = React.forwardRef((props, ref) => {
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  })
  const [type, setType] = React.useState<AlertColor>('success')
  const [msg, setMsg] = React.useState('')
  const { vertical, horizontal, open } = state
  let timer: NodeJS.Timeout

  const handleClick = (msg: string, type: AlertColor, closeTime = 2000) => {
    handleClose()

    setState({ ...state, open: true })

    setType(type)

    setMsg(msg)
    if (timer) clearTimeout(timer)

    timer = setTimeout(() => {
      handleClose()
    }, closeTime)
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  React.useImperativeHandle(ref, () => {
    return { handleClick, handleClose }
  })

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
    >
      <Alert
        severity={type}
        variant="filled"
        sx={{ color: '#fff', fontWeight: '500' }}
      >
        {msg}
      </Alert>
    </Snackbar>
  )
})

export default PositionedSnackbar

// ** MUI Imports
import Box from '@mui/material/Box'
import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

// ** Type Import
import { LayoutProps } from 'src/@core/layouts/types'

// ** Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Menu Components
import HorizontalNavItems from './HorizontalNavItems'

// ** Types
interface Props {
  settings: LayoutProps['settings']
  horizontalNavItems: NonNullable<
    NonNullable<LayoutProps['horizontalLayoutProps']>['navMenu']
  >['navItems']
}

const Navigation = (props: Props) => {
  const containerRef = useRef<any>(null)

  const [containerHeight, setContainerHeight] = useState(0)
  const [horizontalNavItems, setHorizontalNavItems] = useState<any>(
    props.horizontalNavItems
  )

  // 使用lodash的debounce函数

  const debouncedHandleResize = _.debounce(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight)

      if (containerRef.current.offsetHeight >= 60) {
        const arr = _.cloneDeep(props.horizontalNavItems)

        setHorizontalNavItems(
          arr?.map((item: any) => {
            if (item.title === 'Users') item.title = 'user'
            if (item.title === 'Reports') item.title = 'report'
            if (item.title === 'Patients') item.title = 'patient'
            if (item.title === 'Setting') item.title = 'Settings'

            return item
          })
        )
      } else {
        setHorizontalNavItems(props.horizontalNavItems)
      }
    }
  }, 200) // 设置防抖延迟为200毫秒

  useEffect(() => {
    // 初始设置高度

    debouncedHandleResize()

    // 监听窗口大小变化

    window.addEventListener('resize', debouncedHandleResize)

    // 清理函数，在组件卸载时移除监听器

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, []) // 空依赖数组确保这个effect只运行一次

  return (
    <Box
      className="menu-content"
      sx={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        pt: 1,
        pb: 1,
        alignItems: 'center',
        '& > *': {
          // '&:not(:last-child)': { mr: 2 },
          ...(themeConfig.menuTextTruncate && { maxWidth: 220 })
        }
      }}
      id="navigation-group"
      ref={containerRef}
    >
      <HorizontalNavItems {...props} horizontalNavItems={horizontalNavItems} />
    </Box>
  )
}

export default Navigation

// ** MUI Imports
import Box from '@mui/material/Box'
import { Theme, styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import InputAdornment from '@mui/material/InputAdornment'

// ** Next Import
import Link from 'next/link'

// ** Icons Imports
import Menu from 'mdi-material-ui/Menu'
import Magnify from 'mdi-material-ui/Magnify'

// ** Navigation Imports
import VerticalNavItems from 'src/navigation/vertical'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import Navigation from '../horizontal/navigation'
import ChatClaude from './ChatClaude'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  let type = 'window'

  if (/(micromessenger)/i.test(navigator.userAgent)) {
    // alert('微信')
    type = 'mobile'
  } else {
    // alert('普通浏览器')

    // 判断h5还是pc true就是h5
    const client =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    if (client) {
      // alert('h5')
      type = 'mobile'
    } else {
      // alert('pc')
      type = 'window'
    }
  }

  // ** Hook
  const hiddenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <LinkStyled href="/" passHref>
        <img width={150} src="/images/logos/genomelight_logo.png" alt="" />
      </LinkStyled>

      <Box
        className="actions-left"
        sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
      >
        {hidden && type === 'window' ? (
          <>
            <Box
              className="actions-left"
              sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
            >
              <Navigation
                settings={props.settings}
                horizontalNavItems={VerticalNavItems()}
              />
            </Box>
          </>
        ) : (
          <>
            <IconButton
              color="inherit"
              onClick={toggleNavVisibility}
              sx={{ ml: -2.75, ...(hiddenSm ? {} : { mr: 3.5 }) }}
            >
              <Menu />
            </IconButton>

            <TextField
              size="small"
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: 4 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Magnify fontSize="small" />
                  </InputAdornment>
                )
              }}
            />
          </>
        )}
      </Box>

      <Box
        className="actions-right"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <ChatClaude />

        <LanguageDropdown settings={settings} saveSettings={saveSettings} />

        <ModeToggler settings={settings} saveSettings={saveSettings} />

        <NotificationDropdown />

        <UserDropdown />
      </Box>
    </Box>
  )
}

export default AppBarContent

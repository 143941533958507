import { Permissions, User } from '@/@core/services/app.service'
import { AbilityBuilder, Ability } from '@casl/ability'

export type Subjects = string
export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'

export type AppAbility = Ability<[Actions, Subjects]> | undefined

export const AppAbility = Ability as any
export type ACLObj = {
  action: Actions
  subject: string
}

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */
const defineRulesFor = (role: string, subject: string) => {
  const { can, rules } = new AbilityBuilder(AppAbility)
  const user = JSON.parse(localStorage.getItem('user') || '{}') as User

  if (user.role_permission && user.role_permission.length) {
    const permissions = user.role_permission[0].permissions
    const permissionsArr = [] as {
      name: string
      child: Permissions[]
    }[]

    permissions.forEach((items) => {
      const temp = permissionsArr.find(
        (item) => item.name === `${items.resource}_${items.name}`
      )
      if (temp) {
        temp.child.push(items)
      } else
        permissionsArr.push({
          name: `${items.resource}_${items.name}`,
          child: [items]
        })
      if (items.action === 'read')
        permissionsArr.push({
          name: items.resource,
          child: [items]
        })
    })

    permissionsArr &&
      permissionsArr.forEach((item) => {
        can(
          item.child.map((item) => item.action),
          item.name
        )
      })
  }

  // if (role === 'admin') {
  //   can('manage', 'all')
  // } else if (role === 'client') {
  //   can(['read'], 'sample-page')
  // } else {
  //   can(['read', 'create', 'update', 'delete'], subject)
  // }

  // can(['read', 'create'], 'sample-page')

  // can(['read'], 'analysis-page')

  // can(['read'], 'report-page')

  // can(['read'], 'patient-page')

  // can(['read'], 'user-page')

  // can(['read'], 'setting-page')
  console.log('rules', rules)

  return rules
}

export const buildAbilityFor = (role: string, subject: string): AppAbility => {
  return new AppAbility(defineRulesFor(role, subject), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: (object) => object!.type
  })
}

export const defaultACLObj: ACLObj = {
  action: 'manage',
  subject: 'all'
}

export default defineRulesFor

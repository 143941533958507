// ** React Imports
import { ReactNode } from 'react'

// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import MuiToolbar, { ToolbarProps } from '@mui/material/Toolbar'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import { hexToRGBA } from '@/@core/utils/hex-to-rgba'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
  verticalAppBarContent?: (props?: any) => ReactNode
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  transition: 'none',
  alignItems: 'center',
  justifyContent: 'center',

  // padding: theme.spacing(0, 6),

  // backgroundColor: 'white',
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  position: 'sticky',
  zIndex: '1100',
  top: '0px',
  left: 'auto',
  right: '0px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  width: '100%',
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  padding: `${theme.spacing(0)} !important`,
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition:
    'padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out, background-color .25s ease-in-out'
}))

const LayoutAppBar = (props: Props) => {
  // ** Props
  const { settings, verticalAppBarContent: userVerticalAppBarContent } = props

  // ** Hooks
  const theme = useTheme()

  // ** Vars
  const { skin, contentWidth } = settings
  const { appBar, appBarBlur } = { appBar: 'fixed', appBarBlur: 'true' }

  const appBarFixedStyles = () => {
    return {
      px: `${theme.spacing(5)} !important`,
      boxShadow: skin === 'bordered' ? 0 : 3,
      ...(appBarBlur && { backdropFilter: 'blur(8px)' }),
      backgroundColor: hexToRGBA(
        theme.palette.background.paper,
        appBarBlur ? 0.85 : 1
      ),
      ...(skin === 'bordered' && {
        border: `1px solid ${theme.palette.divider}`,
        borderTopWidth: 0
      })
    }
  }

  return (
    <AppBar
      elevation={0}
      color="default"
      className="layout-navbar"
      position="static"
    >
      <Toolbar
        className="navbar-content-container"
        sx={{
          ...(appBar === 'fixed' && { ...appBarFixedStyles() }),
          ...(contentWidth === 'boxed' && {
            '@media (min-width:1440px)': {
              maxWidth: `100%`
            }
          })
        }}

        // sx={{
        //   ...(contentWidth === 'boxed' && {
        //     '@media (min-width:1440px)': { maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)` }
        //   })
        // }}
      >
        {(userVerticalAppBarContent && userVerticalAppBarContent(props)) ||
          null}
      </Toolbar>
    </AppBar>
  )
}

export default LayoutAppBar

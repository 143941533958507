// ** Icon imports
import HomeOutline from 'mdi-material-ui/HomeOutline'
import BagPersonalOutline from 'mdi-material-ui/BagPersonalOutline'
import CreditCardOutline from 'mdi-material-ui/CreditCardOutline'
import AccountPlusOutline from 'mdi-material-ui/AccountPlusOutline'
import GoogleCirclesExtended from 'mdi-material-ui/GoogleCirclesExtended'
import Cog from 'mdi-material-ui/Cog'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'
import AccountMultipleOutline from 'mdi-material-ui/AccountMultipleOutline'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: 'Dashboard',
      icon: HomeOutline,
      path: '/',
      auth: false
    },
    {
      sectionTitle: 'Analyze'
    },
    {
      title: 'Single Sample',
      icon: BagPersonalOutline,
      path: '/samples',
      openInNewTab: false,
      auth: true,
      action: 'read',
      subject: 'sample-page'
    },
    {
      title: 'Multi Samples',
      icon: AccountMultipleOutline,
      path: '/analyze',
      openInNewTab: false,
      auth: true,
      action: 'read',
      subject: 'analysis-page'
    },
    {
      sectionTitle: 'User'
    },
    {
      title: 'Users',
      icon: AccountPlusOutline,
      path: '/users',
      auth: true,
      action: 'read',
      subject: 'user-page'
    },
    {
      title: 'Reports',
      icon: CreditCardOutline,
      path: '/reports',
      auth: true,
      action: 'read',
      subject: 'report-page'
    },
    {
      title: 'Patients',
      icon: GoogleCirclesExtended,
      path: '/patients',
      auth: true,
      action: 'read',
      subject: 'patient-page'
    },
    {
      title: 'Setting',
      icon: Cog,
      path: '/setting',
      auth: true,
      action: 'read',
      subject: 'setting-page'
    }

    // {
    //   title: 'Tables',
    //   icon: Table,
    //   path: '/tables'
    // },
    // {
    //   icon: CubeOutline,
    //   title: 'Form Layouts',
    //   path: '/form-layouts'
    // }
  ]
}

export default navigation

// ** MUI Imports
import { PaletteMode, Direction } from '@mui/material'

// ** Types
import {
  ContentWidth,
  Skin,
  HorizontalMenuToggle
} from 'src/@core/layouts/types'

type ThemeConfig = {
  mode: PaletteMode
  templateName: string
  routingLoader: boolean
  disableRipple: boolean
  navigationSize: number
  menuTextTruncate: boolean
  contentWidth: ContentWidth
  responsiveFontSizes: boolean
  skin: Skin
  direction: Direction
  navSubItemIcon: string
  horizontalMenuToggle: HorizontalMenuToggle
  horizontalMenuAnimation: boolean
}

const themeConfig: ThemeConfig = {
  // ** Layout Configs
  templateName: 'GLSofort' /* App Name */,
  mode: 'light' /* light | dark */,
  contentWidth: 'boxed' /* full | boxed */,
  skin: 'default' /* default | bordered */,
  direction: 'ltr' /* ltr | rtl */,

  // ** Routing Configs
  routingLoader: true /* true | false */,

  // ** Navigation (Menu) Configs
  menuTextTruncate: true /* true | false */,
  navSubItemIcon: 'mdi:circle-outline' /* Icon */,
  horizontalMenuAnimation: true /* true | false */,
  horizontalMenuToggle:
    'hover' /* click | hover /*! Note: This is for Horizontal navigation menu only */,
  navigationSize: 260 /* Number in PX(Pixels) /*! Note: This is for Vertical navigation menu only */,

  // ** Other Configs
  responsiveFontSizes: true /* true | false */,
  disableRipple: false /* true | false */
}

export default themeConfig

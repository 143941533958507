// ** React Imports
import { useState, SyntheticEvent, Fragment, useRef } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Icons Imports
import CogOutline from 'mdi-material-ui/CogOutline'
import CurrencyUsd from 'mdi-material-ui/CurrencyUsd'
import EmailOutline from 'mdi-material-ui/EmailOutline'
import LogoutVariant from 'mdi-material-ui/LogoutVariant'
import AccountOutline from 'mdi-material-ui/AccountOutline'
import MessageOutline from 'mdi-material-ui/MessageOutline'
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import { useMutation } from 'react-query'
import { logout } from '@/@core/services/app.service'
import PositionedSnackbar from '@/@core/components/message'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { getVersion } from '../../utils'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = () => {
  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const messageRef = useRef<any>()

  // ** Hooks
  const router = useRouter()

  const { t } = useTranslation()

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }

    setAnchorEl(null)
  }

  const logouts = useMutation(logout, {
    onSuccess: (data) => {
      if (data) {
        localStorage.removeItem('user')

        sessionStorage.removeItem('token')

        sessionStorage.removeItem('userData')

        sessionStorage.removeItem('columnOrder')

        messageRef?.current?.handleClick(t('Logout successful'), 'success')

        handleDropdownClose('/pages/login')
      }
    },
    onError(error, variables, context) {
      console.log(error)

      messageRef?.current?.handleClick(
        t('Failed logout') + String(error),
        'error'
      )
    }
  })

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  return (
    <Fragment>
      <Badge
        overlap="circular"
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {/* <Avatar
          alt="John Doe"
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src="/images/avatars/1.png"
        /> */}
        <Avatar
          sx={{
            bgcolor: '#1e446e',
            color: 'rgb(255, 255, 255)',
            fontSize: '12px'
          }}
        >
          {JSON.parse(localStorage.getItem('user') || '{}').username}
        </Avatar>
      </Badge>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Avatar
                sx={{
                  bgcolor: '#1e446e',
                  color: 'rgb(255, 255, 255)',
                  fontSize: '12px'
                }}
              >
                {JSON.parse(localStorage.getItem('user') || '{}').username}
              </Avatar>
            </Badge>

            <Box
              sx={{
                display: 'flex',
                marginLeft: 3,
                alignItems: 'flex-start',
                flexDirection: 'column'
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {JSON.parse(localStorage.getItem('user') || '{}').username}
              </Typography>

              <Typography
                variant="body2"
                sx={{ fontSize: '0.8rem', color: 'text.disabled' }}
              >
                {JSON.parse(localStorage.getItem('user') || '{}').roleName}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* <Divider sx={{ mt: 0, mb: 1 }} />

        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <AccountOutline sx={{ marginRight: 2 }} />
            Profile
          </Box>
        </MenuItem> */}

        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <EmailOutline sx={{ marginRight: 2 }} />
            Inbox
          </Box>
        </MenuItem>

        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <MessageOutline sx={{ marginRight: 2 }} />
            Chat
          </Box>
        </MenuItem> */}

        <Divider />

        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <CogOutline sx={{ marginRight: 2 }} />

            {t('Settings')}
          </Box>
        </MenuItem> */}

        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <CurrencyUsd sx={{ marginRight: 2 }} />
            Pricing
          </Box>
        </MenuItem> */}

        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Tooltip placement="top-end" title={getVersion()}>
            <Box sx={styles}>
              <HelpCircleOutline sx={{ marginRight: 2 }} />

              {t('Version number')}
            </Box>
          </Tooltip>
        </MenuItem>

        <Divider />

        <MenuItem
          sx={{ py: 2 }}
          onClick={() => {
            const user = JSON.parse(localStorage.getItem('user') || '{}')
            const token = JSON.parse(localStorage.getItem('token') || '{}')

            logouts.mutateAsync({
              username: user.email as string,
              refreshToken: token.refreshToken as string
            })

            // handleDropdownClose('/pages/login')
          }}
        >
          <LogoutVariant
            sx={{
              marginRight: 2,
              fontSize: '1.375rem',
              color: 'text.secondary'
            }}
          />

          {t('Logout')}
        </MenuItem>
      </Menu>

      <PositionedSnackbar ref={messageRef} />
    </Fragment>
  )
}

export default UserDropdown
